.custom-bg-1 {
  background-color: #f59301;
}

.custom-bg-2 {
  background-color: #2e81ed;
}

.mention-heading-text {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.suggestion-child {
  max-height: 200px;
  overflow-y: auto;
}
