.menu-label {
	font-size: 11px;
	font-weight: 600;
	/* color: #9ca3af; */
	color: #7e8387;
}
.menu-name {
	font-size: 10px;
	font-weight: 600;
	color: #808485;
}
.menu-label-disable {
	font-size: 11px;
	font-weight: 600;
	color: #c5c8cb;
}
.menu-value {
	font-size: 11px;
	font-weight: 600;
	color: #43474b;
}
.disable-icon-color {
	color: #c5c8cb;
}
.enable-icon-color {
	color: #075985;
}
/* .right-line::after {
	content: '';
	position: absolute;
	top: 0;
	right: -10px;
	height: 90%;
	width: 1px;
	background-color: #d1d5db;
} */
.right-line {
	display: flex;
}

.right-line::after {
	content: "";
	flex-shrink: 0;
	margin-left: 12px;
	margin-right: 12px;
	height: 90%;
	width: 1px;
	background-color: #d1d5db;
}

.filter-line {
	display: inline-block; /* Ensure the span acts like a block-level element */
	position: relative; /* Positioning for the pseudo-element */
	padding-bottom: 8px; /* Adds spacing between text and line */
}

.filter-line::after {
	content: "";
	position: absolute;
	bottom: 0; /* Place the line at the bottom of the span */
	left: 0;
	width: 100%; /* Make the line span the full width of the text */
	height: 1px; /* Line thickness */
	background-color: #d1d5db; /* Line color */
}
.hover-container:hover .img-container {
	box-shadow:
		rgba(0, 0, 0, 0.16) 0px 3px 6px,
		rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.MuiSvgIcon-fontSizeMedium {
	width: "30px";
}
.expandedpasteicon {
	width: 30px !important;
	height: 34px !important;
}

.collapsedpasteicon {
	width: 22px !important;
	height: 21px !important;
}
.expandedDataviewicon {
	width: 32px !important;
	height: 32px !important;
}
.collapsedDataviewicon {
	width: 24px !important;
	height: 24px !important;
}
.dollar-iconexpand {
	width: 21px !important;
	height: 21px !important;
}
.dollar-iconcollapse {
	width: 20px !important;
	height: 20px !important;
}
.downarrow-iconexpand {
	width: 20px !important;
	height: 20px !important;
}
.downarrow-iconcollapse {
	width: 19px !important;
	height: 19px !important;
}
.tablestyle {
	font-size: 12px;
	border: 1px solid #d1d5db;
}
.inputborder-custome {
	border: 1px solid #9ca3af;
}
.datamanagement-custom-scrollbar::-webkit-scrollbar {
	width: 7px;
	height: 5px;
}
.datamanagement-custom-scrollbar::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 5px;
}

.datamanagement-custom-scrollbar::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 5px;
}

.datamanagement-custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.pagination-style .MuiSelect-select {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.pagination-style .MuiToolbar-root {
	align-items: end;
}
.pagination-style .MuiButtonBase-root {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.pagination-style .MuiTablePagination-root {
	min-height: 26px !important;
}
.pagination-style .MuiInputBase-root {
	margin-right: 7px !important;
	margin-left: 3px !important;
}
.pagination-style .MuiTablePagination-actions {
	margin-left: 2px !important;
}
.pagination-style .MuiTablePagination-toolbar {
	min-height: 24px !important;
}
.selectedformat {
	border: 1px solid #075985 !important;
	border-radius: 5px;
}
.defaultselectedformat {
	border: 1px solid #ecf4fa !important;
}
.arrowdown {
	height: 15px !important;
	width: 15px !important;
}
.agg-list {
	padding-left: 0px !important;
}

/* .tablestyle {
	user-select: none;
}

.tablestyle input,
.tablestyle textarea {
	user-select: text;
} */

.truncate-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.pagesize-list {
	padding-left: 0px !important;
}
.fileicon {
	height: 30px !important;
	width: 30px !important;
}
.collapse-fileicon {
	height: 25px !important;
	width: 25px !important;
}
.queryicon {
	width: 22px !important;
}

.error-msg {
	border: 1px solid #e04355 !important;
}
.datasetbtn {
	width: 17px !important;
}
