.section-card {
  background-color: #fff;
  padding: 8px;
  cursor: pointer;
}

.avatar-user {
  width: 25px;
  height: 25px;
  font-size: 10px;
}

.section-count {
  font-size: 12px !important;
  color: #bdbdbd;
}

.button-unset {
  all: unset;
}

.delete-button {
  width: 16;
  height: 16;
  object-fit: contain;
}

.right-icon {
  color: #bdbdbd;
  font-size: 16px;
}
