.cancel-text-1 {
  color: #2f80edcc;
}

.add-appointment {
  color: #272e35;
  font-weight: 700 !important;
  font-size: 28px !important;
}

.add-date {
  color: #828282;
}

.provider-name {
  color: #333333;
}

.eligible-appointments {
  font-size: 18px !important;
  font-weight: 450 !important;
  color: #272e35;
}

.appointment-name {
  color: #3062d4;
  font-weight: 450 !important;
  line-height: 32px !important;
}

.appointment-sub {
  color: #828282;
  line-height: 24px !important;
}

.app-card {
  padding: 12px;
}

.para {
  line-height: 28px !important;
  color: #4a545e;
}

.highlighted-text {
  font-weight: 600 !important;
}

.button-confirm {
  background-color: #2f80ed !important;
  color: #fff !important;
  font-size: 14px !important;
}

.button-secondary {
  background-color: #fff !important;
  color: #4a545e;
  border: 1px solid #cfd6dd !important;
  font-size: 14px !important;
}

.custom-option {
  padding: 8px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.custom-option.focused {
  background-color: #f0f0f0; /* Change to your desired hover color */
}

.custom-option.selected {
  background-color: #007bff; /* Change to your desired selected color */
  color: white;
}
