.widget-icons {
    color: #075985;
    height: 25px !important;
    width: 25px !important;
}
.sidebar-label {
    font-size: 12px;
}
.input-border-custome {
    border: 1px solid #9ca3af;
}
.sidebar-section-text {
    font-size: 11px;
    color: #6b7280;
    font-weight: 600;
}
.sidebar-section-label {
    font-size: 11px;
    font-weight: 600;
    /* color: #9ca3af; */
    color: #6b7280;
}
.prop-main-label {
    font-weight: 600;
    font-size: 11px;
    color: #4b5563;
}
.color-picker {
    width: 160px !important;
}
[id^="rc-editable-input-"] {
    font-size: 9px !important;
    padding: 4px 7% 3px !important;
    width: 100% !important;
}
.ck.ck-balloon-panel.ck-balloon-panel_visible {
    display: none !important;
}
.ck-toolbar {
    /* position: absolute !important; */
    /* top: -100px; */
    width: 450px !important;
    border: 1px solid #ccced1 !important;
}
.ck .ck-editor__editable_inline {
    border: none !important;
}
.ck .ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
    border: none !important;
    border-width: none !important;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    box-shadow: none !important;
}

p {
    margin-bottom: 0px !important;
}
.ck.ck-editor__editable_inline > :first-child {
    margin-top: 0px !important;
}
.ck .ck-toolbar {
    top: "-100px" !important;
    left: "-5px" !important;
}
.ck.ck-editor {
    position: unset !important;
    overflow: auto !important;
    height: 100% !important;
    width: 100% !important;
}
.ck.ck-editor::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.ck.ck-editor::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.ck.ck-editor::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

.ck.ck-editor::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.hidden-toolbar .ck-toolbar {
    display: none !important;
}
.ck.ck-editor__main > .ck-editor__editable {
    background-color: unset !important;
}
.ck-toolbar_vertical {
    top: 5px !important;
    width: 50px !important;
}
.dashboarddata-list {
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px 7px;
    padding-left: 30px;
    margin-top: 1px;
}
.dashboarddata-list:hover {
    background-color: #e5e5e5;
}
.dashboarddata-list-ul {
    max-height: 80vh;
}
.rnd-container {
    transform: none !important;
}
.ck-list {
    height: 160px !important;
    overflow: auto;
}
.ck-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.ck-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

.ck-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

.ck-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.ck-dropdown__button {
    cursor: pointer !important;
}
.ck-button {
    cursor: pointer !important;
}
.dashboard-border-custome {
    border: 1px solid #c7d8eb;
}
.filter-righticon {
    width: 19px !important;
    height: 18px !important;
}
.filter-panel-date .css-1jr6r0j-MuiStack-root-MuiMultiInputDateRangeField-root > :not(style) + :not(style) {
    margin-left: 5px !important;
}
.filter-panel-date .MuiStack-root {
    overflow: initial !important;
}
.filter-panel-date .MuiInputLabel-root {
    color: #bdbdbd !important;
    font-size: 12px !important;
    line-height: 1.5rem !important;
    top: -15px !important;
    left: -7px !important;
}
.filter-panel-date .MuiStack-root {
    padding-top: 0px !important;
}
.filter-panel-date .MuiOutlinedInput-notchedOutline {
    display: none !important;
}
.filter-panel-date .MuiInputBase-input {
    padding: 0.2rem 0.3rem !important;
    outline: none !important;
    border-radius: 0.375rem !important;
    border: 1px solid #9ca3af !important;
    font-size: 12px !important;
    color: #5f6673 !important;
    background-color: white !important;
}
.filter-panel-date .MuiInputLabel-shrink {
    display: none !important;
}
.filter-panel-date .Mui-disabled {
    cursor: not-allowed !important;
}
.report-top-date-range .Mui-disabled {
    cursor: not-allowed !important;
}
/* .filter-panel-date .css-10e3ze8-MuiStack-root {
    width: 70% !important;
} */
.table-total {
    line-height: unset !important;
}
.filter-arrow-icon {
    height: 18px !important;
    width: 20px !important;
}
.filter-border-custome {
    border: 1px solid #cccdd0;
}

.report-top-date-range .css-1jr6r0j-MuiStack-root-MuiMultiInputDateRangeField-root > :not(style) + :not(style) {
    margin-left: 5px !important;
}
.report-top-date-range .MuiStack-root {
    overflow: initial !important;
}
.report-top-date-range .MuiInputLabel-root {
    color: #bdbdbd !important;
    font-size: 12px !important;
    line-height: 1.5rem !important;
    top: -15px !important;
    left: -7px !important;
}
.report-top-date-range .MuiStack-root {
    padding-top: 0px !important;
}
.report-top-date-range .MuiOutlinedInput-notchedOutline {
    display: none !important;
}
.report-top-date-range .MuiInputBase-input {
    padding: 0.2rem 0.3rem !important;
    outline: none !important;
    border-radius: 0.375rem !important;
    border: 1px solid #9ca3af !important;
    font-size: 12px !important;
    color: #5f6673 !important;
    background-color: white !important;
}
.report-top-date-range .MuiInputLabel-shrink {
    display: none !important;
}
/* .filter-panel-date .MuiStack-root {
    width: 85% !important;
} */
/* .report-top-date-range .css-10e3ze8-MuiStack-root {
    width: 70% !important;
} */

.report-designer .MuiTabs-flexContainer button {
    border-radius: 5px !important;
    background-color: #d3e4f1 !important;
    color: #414142;
    text-transform: capitalize;
    font-weight: 600 !important;
    min-height: 0px !important;
    padding: 4px 0px !important;
    font-size: 11px !important;
    border: 2px solid #d3e4f1 !important;
}

.report-designer .Mui-disabled {
    color: #acacae !important;
}
.report-designer .css-1ujnqem-MuiTabs-root {
    min-height: 0px !important;
    margin-bottom: 0px !important;
}

.report-designer .MuiTabs-flexContainer .Mui-selected {
    color: #000000 !important;
    border: 2px solid black !important;
}

.report-designer .MuiTabs-flexContainer {
    gap: 0.5rem !important;
}

.search-icon-menu {
    position: absolute;
    color: #c9cacb;
    right: 4px;
    top: 2px;
}
.tablestyle-reportview {
    font-size: 12px;
}
.table-header-cell {
    position: relative;
}

.table-header-cell:hover::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
    cursor: col-resize;
}
.filter {
    margin-top: 0 !important;
}
/* .react-grid-layout {
    background-color: transparent !important;
} */
.react-grid-item {
    background-color: transparent !important;
}
