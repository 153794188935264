.avatar {
  background-color: #555f6d;
}

.provider-card-name {
  font-family: Inter !important;
  font-weight: 450 !important;
}

.provider-card-subtext {
  font-family: Inter !important;
  /* font-weight: 450 !important; */
  font-size: 14px !important;
  color: #555f6d;
}

.provider-card-subhead {
  color: #333333;
  font-weight: 700 !important;
}

.provider-visits {
  font-family: Inter;
  font-weight: 450;
  color: #555f6d;
}

.provider-visit-number {
  font-family: Inter;
  font-weight: 450;
  color: #3062d4;
}
