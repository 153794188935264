.new {
  background-color: #f3ca50 !important;
}

.cancelled {
  background-color: #eb5757 !important;
}

.checkIcon {
  width: 25px;
  height: 25px;
}
.mainText {
  color: #272e35;
  font-weight: 700 !important;
  font-family: Inter !important;
  font-size: 22px !important;
}

.secondaryText {
  color: #272e35;
  font-weight: 450 !important;
  font-family: Inter !important;
  font-size: 19px !important;
}

.subText {
  color: #828282;
  /* font-size: 14px !important; */
  font-family: Inter !important;
}

.proText {
  color: #272e35;
  font-size: 18px !important;
  font-family: Inter !important;
  font-weight: 450;
}

.cancel-text {
  color: #4a0d0d;
}
