.button-inactive {
  padding: 5px !important;
  flex: 1;
  border: 1px solid #cfd6dd !important;
  align-items: "center";
  /* margin: 0px 5px; */
  border-radius: 6px !important;
  font-weight: 450px !important;
}

.button-active {
  border: 2px solid #3062d4 !important;
}

.button-active-text {
  color: #3062d4 !important;
  font-weight: 450px !important;
}

.unread-position {
  position: absolute;
  right: 10px;
  top: 1px;
}
