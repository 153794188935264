.visit-details-title {
  font-size: 22px !important;
}

.close-icon-visit {
  color: #2f80ed;
  font-size: 16px;
}

.text-cancel {
  color: #eb5757 !important;
}

.text-blue {
  color: #2f80ed !important;
}

.visit-detail {
  margin-left: 20px !important;
  font-weight: 450 !important;
  color: #272e35;
}

.visit-detail-sub {
  color: #828282;
}
