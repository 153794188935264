div[style*="cursor: row-resize"] {
  background-color: #f7f9fb !important;
  height: 5px !important;
  /* display: none !important; */
}

.dashboard-close-icon {
  width: 25px !important;
  height: 25px !important;
  color: #075985 !important
}
.custom-echart  > div:first-child {
  width: 100% !important;
  height: 100% !important;
}

.custom-echart canvas {
  width: 100% !important;
  height: 100% !important;
}
.big-number-icon{
  width: 25px !important;
  height: 25px !important;
  color: #075985 !important
}
.type-icon{
  height: 15px !important;
  width:15px !important
}