.button-appointment {
  padding: 2px 5px;
  border-radius: 6px;
  border: 1px solid #cfd6dd;
  align-items: "center";
  background-color: #fff;
}
.title-text {
  font-weight: 450 !important;
  color: #272e35;
  font-size: 14px !important;
  font-family: Inter !important;
}
.disable {
  opacity: 0.5;
  color: #fff !important;
}
.redScheme {
  border: 0px;
  border: 2px solid #eb5757 !important;
}
.redText {
  color: #eb5757;
}
.blueText {
  color: #3062d4;
}

.blueScheme {
  border: 2px solid #3062d4 !important;
  background-color: #3062d4;
}

.redfilledScheme {
  background-color: #eb5757;
}

.pendingfilledScheme {
  background-color: #f0f3f5;
  /* color: #fff; */
  border: none !important;
}

.greenfilledScheme {
  background-color: #27ae60;
}

.greenfilledText {
  color: #fff;
}

.yellowfilledScheme {
  background-color: #f3ca50;
}

.yellowfilledText {
  color: #fff;
}

.redfilledText {
  color: #fff;
}
