.header {
  background-color: #2f80ed;
}

.header-full {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

.footer-chat {
  background-color: #d9d9d9;
}

.chat-title {
  color: #fff;
  font-family: Inter !important;
  font-weight: 700 !important;
}

.chat-title-full {
  color: #000;
}

.icon-chat {
  width: 12px;
  height: 12px;
}

.chat-subtext {
  color: #4a545e;
  font-family: Inter !important;
  font-size: 12px !important;
}

.close-icon {
  width: 25px;
  height: 25px;
}

.send {
  color: #3062d4;
  font-family: Inter !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.mention-div textarea::-webkit-scrollbar {
  display: none;
}

.border-0__highlighter__substring {
  visibility: visible !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.chat-input textarea::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-input textarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
