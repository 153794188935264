/* .container {
    flex: 1;
    justifyContent: "center";
    alignItems: "center";
    margin: 10;
  } */

.triangleShapeCSS {
  width: 0px;
  height: 0px;
  border-left: 8px;
  border-right: 8px;
  border-bottom: 20px;
  transform: rotate(-90deg);
  border-style: solid;
  background-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-bottom-color: #e9ecef;
}
.leftMessage {
  position: absolute;
  left: -15px;
  top: 5px;
}
.rightMessage {
  border-bottom-color: #f2c94c;
  transform: rotate(90deg);
  position: absolute;
  right: -15px;
  top: 5px;
}

.message-card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 8px;
  /* width: 70%; */
  /* max-width: 70%; */
  min-width: 200px;
  margin: 5px;
  position: relative;
}

.message-card-70 {
  max-width: 70%;
}

.message-card-full {
  width: 90%;
}

.user-message-card {
  background-color: #2f80ed !important;
}

.person-name {
  color: #eb5757;
  font-weight: 700 !important;
}

.community-name {
  color: #aaa;
  /* font-weight: 700 !important; */
  font-size: 12px !important;
}
.message-text {
  text-align: left;
}

.chat-time {
  align-self: flex-end;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
  font-family: Inter !important;
}

.options-menu {
  position: absolute;
  right: 5px;
  top: 1px;
  color: #272e35;
  font-size: 20px;
}

.group-created-card {
  display: flex;
  justify-content: center;
}

.chat-image {
  width: 100%;
  max-height: 300px;
  margin: 10px 0px;
}

.forward-msg {
  /* position: absolute;
  bottom: 10px;
  left: 15px; */
  color: #b2b2b3;
  padding-top: 5px;
}

.forward-text {
  color: #fff;
  font-size: 10px !important;
}

.forward-color {
  color: #b2b2b3 !important;
}

.forward-icon {
  color: #fff;
  font-size: 15px !important;
  margin-right: 5px;
}

.star-icon {
  color: #fff;
  font-size: 12px !important;
}

.success-color {
  color: #27ae60;
}

.sent-to-athena {
  width: 70%;
  max-width: 400px;
}

.download-icons {
  width: 50px !important;
  height: 50px !important;
}
