.icon1 {
  width: 18px;
  height: 18px;
}

.filter-buttons {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #e0e0e0 !important;
}

.providersFilter {
  background-color: #3062d4 !important;
  border-radius: 5px;
}

.tagBg {
  background-color: #f2c94c !important;
}

.providerText {
  font-weight: 450;
  font-family: Inter;
}

.full-inbox {
  font-weight: 700 !important;
  font-size: 18px !important;
  text-decoration: underline;
}
