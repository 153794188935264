.menu-icon {
  width: 30px;
  height: 30px;
}
.icon1 {
  width: 20px;
  height: 20px;
  align-items: center;
}

.fullscreen-text {
  color: #075985;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
}

.maxView {
  background-color: #075985;
  border-radius: 6px;
  margin-right: 10px;
}
.username {
  flex-grow: 1;
  font-family: Inter !important;
}

.sidebar-title {
  font-size: 32px;
  color: #000;
  font-weight: 600;
  /* margin-right: auto; */
  font-family: Inter;
}

.sidebar-subtitle {
  font-size: 32px;
  color: #075985;
  font-weight: 600;
  font-family: Inter;
}

.sidebar-text {
  font-family: Inter !important;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  /* background-color: #075985; */
  border-top: 1px solid #ccc;
}

.sidebar-icon {
  width: 20px;
  height: 20px;
}

.selected-section {
  font-weight: 700 !important;
  color: #075985;
}

.section-title {
  color: #4a545e;
}

.normal-options {
  font-weight: 450 !important;
}

.selected-option {
  background-color: #e5eeff !important;
  color: #075985 !important;
}

.normal-option {
  background-color: #fff;
  color: #4a545e !important;
}

.header-main {
  margin-bottom: 10px;
}

.logo {
  width: 200px;
  height: 50px;
}
.logo-skorboard {
  width: 160px;
  height: 25px;
}
.logo-skorboard2 {
  width: 200px;
  height: 35px;
}
/* .menu-header .MuiButtonBase-root {
  min-width: 165px !important;
} */
.menu-header .MuiButtonBase-root {
  min-width: 31px !important;
}
